import { graphql } from "gatsby"
import React from "react"
import {
  email,
  phoneNumber,
  tel,
  chat
} from "../../../components/footer/footer-constants"
import styles from "./outreach-projects.module.scss"
import { Page } from "@components/page/page"
import { defaultMetadata, PageOpenGraphImage } from "@core/constants"
import { SupportBox } from "@features/about/components/support-box/support-box"
import { Markdown } from "@components/markdown/markdown"
import { Icon } from "@components/icon/icon"
import { Link } from "@components/link/link"
import { ToHTMLFormatYoutubeEmbed } from "@components/to-html/to-html-format-youtube-embed"

export const OutreachProjectsPage = (props: any) => {
  const { data, ...rest } = props
  const details = data?.contentfulOutreachProjects

  const supportBox = {
    title: details.hero?.title || "",
    subtitle: details.hero?.subtitle,
    image: details.hero?.image?.fluid?.src || "",
    button: {
      text: details.hero?.buttonText || "",
      url: details.hero?.buttonUrl || ""
    }
  }

  return (
    <Page
      {...rest}
      metadata={{
        ...defaultMetadata,
        title: "Outreach Projects | " + defaultMetadata.title,
        openGraphImage: PageOpenGraphImage.OutreachProjects
      }}
    >
      <SupportBox className={styles.supportBox} {...supportBox} />
      <section className={styles.description}>
        <div className={styles.content}>
          <ToHTMLFormatYoutubeEmbed
            text={details?.description?.childMarkdownRemark?.html}
          />
        </div>
      </section>
      <section className={styles.ourProjects}>
        <div className={styles.projectsContent}>
          <div className={styles.projects}>
            {details?.projects?.map((project: any, i: number) => {
              return (
                <Link
                  to={project.urlLink || ""}
                  className={styles.project}
                  key={`${project.title}-${i}`}
                >
                  {project.image && (
                    <img
                      src={project?.image?.fluid?.src}
                      className={styles.image}
                    />
                  )}
                  <div className={styles.text}>
                    <Markdown
                      className={styles.projectName}
                      content={
                        project?.title?.childMarkdownRemark?.rawMarkdownBody
                      }
                    />
                    <ToHTMLFormatYoutubeEmbed
                      className={styles.projectBody}
                      text={project?.description?.childMarkdownRemark?.html}
                    />
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </section>
      <section className={styles.help}>
        <Icon className={styles.icon} variant="24-chat" />
        <h3 className={styles.heading}>How may we help?</h3>
        <p className={styles.helpText}>
          Our team would be happy to answer any questions you may have about our
          outreach or the ways you can help further the mission. Please contact
          us using the <a href={chat}>chat bubble</a>, <a href={email}>email</a>
          , or calling <a href={tel}>{phoneNumber}</a>.
        </p>
      </section>
    </Page>
  )
}

export default OutreachProjectsPage

export const query = graphql`
  query OutreachProjectsPage {
    contentfulOutreachProjects {
      id
      hero {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
        buttonText
        buttonUrl
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      projects {
        image {
          fluid {
            src
          }
        }
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        urlLink
      }
    }
  }
`
